import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header/HeaderAdmin'
import Sidebar from './Sidebar/Sidebar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
const AdminMain = () => {

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={10000}
      />
      <div className="flex flex-col h-screen bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left font-outfit ">
        <Header />
        <div className="flex flex-1 overflow-hidden w-full">
          <div className="w-[300px]">
            <Sidebar />
          </div>
          <div className=" flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-darkshadow-inner pt-8">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminMain
