import { Alert, Card } from "@material-tailwind/react";
import React from "react";
import { NavLink } from "react-router-dom";
import DropdownChoose from "../ChooseServer/DropdownChoose";
import dataSidebar from "./dataSidebar";
import Tip from "../components/ui/tip";
import Notice from "./Notice";


const Sidebar = () => {
  const [open, setOpen] = React.useState(0);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const data = dataSidebar;

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <Card
        color="transparent"
        className="m-0 h-full w-full shadow-2xl shadow-main text-white rounded-none border-r-2 border-main"
      >
        <div className="w-full absolute top-0 left-0 px-2 py-6 z-30">
          <DropdownChoose />
        </div>
        <div className="mt-20 flex flex-col gap-y-px pl-2 ">
          <hr className="my-3 border-third" />
          {data?.filter(item => item.enable).map((data, index) => (
            <NavLink
              key={index}
              to={data.link}
              className={({ isActive }) =>
                `w-full h-12 flex items-center gap-2 pl-4  font-medium transition-all duration-500 ${isActive ? "bg-gradient-to-l from-main hover:from-main/90 to-transparent text-white pl-6 shadow-lg shadow-main/20" : "hover:pl-5 bg-none hover:bg-main/10"}`
              }
            >
              <div className="w-full flex gap-x-2">
                <div className="h-5 w-5">{data.icon}</div>
                {data.name}
              </div>
              <div className="pr-2">
                {data.tip && (
                  <Tip
                    tip={data.tip}
                    placement="right-end"
                  />
                )}
              </div>
            </NavLink>
          ))}
        </div>
        <div className="mt-auto w-full hidden">
          <div className="p-3 w-full">
            <Alert
              open={openAlert}
              className="bg-dark p-5  w-full h-[350px] shadow-xl shadow-main/15"
              onClose={() => setOpenAlert(false)}
            >
              <Notice />
            </Alert>
          </div>
        </div>
      </Card >
    </>
  );
};

export default Sidebar;