import React, { useState } from 'react'
import crossPrice from './../../img/crossPrice.png'
import priceImg from './../../img/priceBg.png'
import priceIcon from './../../img/priceIcon.png'
import tickPrice from './../../img/tickPrice.png'
import { Card, CardHeader, CardBody, CardTitle } from '@material-tailwind/react'
import { dataPrices } from './dataPrices'

const CardList = () => {
  const [activeButton, setActiveButton] = useState(1)

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId)
  }

  return (
    <>
      <style>
        {`@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");`}
      </style>

      <div className="w-11/12 mx-auto mb-40 ">
        <div className="mb-16 flex justify-center">
          <button
            className={`w-32 h-11 rounded-full font-bold text-lg text-white ${activeButton === 1 ? 'bg-gradient-to-r from-[#1bf5a0] to-[#9945ff]' : 'bg-dark'}`}
            onClick={() => handleButtonClick(1)}
          >
            Monthly
          </button>
          <button
            className={`w-32 h-11 mx-2 rounded-full font-bold text-lg text-white ${activeButton === 2 ? 'bg-gradient-to-r from-[#1bf5a0] to-[#9945ff]' : 'bg-dark'}`}
            onClick={() => handleButtonClick(2)}
          >
            6 Months
          </button>
          <button
            className={`w-32 h-11 rounded-full font-bold text-lg text-white ${activeButton === 3 ? 'bg-gradient-to-r from-[#1bf5a0] to-[#9945ff]' : 'bg-dark'}`}
            onClick={() => handleButtonClick(3)}
          >
            Yearly
          </button>
        </div>
        {/* Card de mensalidades */}
        <div className='flex flex-col xl:flex-row items-center justify-center w-full'>
          {dataPrices.map((price, index) => (
            <Card key={index} color='transparent' className='w-[400px] h-[750px] relative mx-5 mb-16 flex items-center'>
              <img src={priceImg} alt="price" className='w-full h-full absolute top-0 left-0 z-0' />
              <CardHeader className='text-white bg-gradient-to-r from-[#1bf5a0] to-[#9945ff] flex items-center justify-center text-base font-bold w-32 h-11 rounded-full -mt-5 capitalize'>
                {activeButton === 1 && <span>{price.price.free || 'monthly'}</span>}
                {activeButton === 2 && <span>{price.price.free || 'semesterly'}</span>}
                {activeButton === 3 && <span>{price.price.free || 'yearly'}</span>}
              </CardHeader>
              <CardBody className='flex flex-col z-10 w-full mt-10'>
                <div className='flex w-full h-full items-center justify-between py-2'>
                  <div className='flex items-center gap-x-3'>
                    <img src={priceIcon} alt="price" className='w-14 h-14' />
                    <div className='flex flex-col items-start'>
                      <div className='text-xl text-white font-bold'>
                        {price.name}
                      </div>
                      <div className='text-white text-sm font-medium'>{price.description}</div>
                    </div>
                  </div>
                  <div className="font-bold text-lg text-center bg-gradient-to-r from-[#9945ff] to-[#1bf5a0] bg-clip-text text-transparent justify-end">
                    {activeButton === 1 && <span>{price.price.monthly || price.price.free}</span>}
                    {activeButton === 2 && <span>{price.price.semesterly || price.price.free}</span>}
                    {activeButton === 3 && <span>{price.price.yearly || price.price.free}</span>}
                  </div>


                </div>
                <hr className='w-full border-[#3f3939] py-2' />
                <div>
                  {price.included.map((item, index) => (
                    <div key={index} className='flex items-center gap-x-3 py-2'>
                      <img src={tickPrice} alt="tick" />
                      <div className='text-white font-medium text-base'>
                        {item}
                      </div>
                    </div>
                  ))}
                  {price.excluded.map((item, index) => (
                    <div key={index} className='flex items-center gap-x-3 py-2'>
                      <img src={crossPrice} alt="cross" />
                      <div className='text-white font-medium text-base'>
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </>
  )
}

export default CardList