import React, { useState } from 'react'
import { Card, List, ListItem, Accordion, AccordionHeader, AccordionBody, Button, Spinner } from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';


const DropdownChoose = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const [servers, setServers] = useState(JSON.parse(localStorage.getItem('myServers')) || []);
  const [serverSelected, setServerSelected] = useState(localStorage.getItem('guildId') || null);


  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleChangeServer = (server) => {
    setOpen(0)
    localStorage.setItem('selectedServer', server.id)
    localStorage.setItem('guildId', server.id)
    setServerSelected(server.id)
    navigate('/dashboard')
  }

  const handleSelectServer = () => {
    navigate('/select-server')
  }

  return (
    <>
      <Card className="bg-second text-white w-full ">
        <List className='w-full text-white p-0 ' >
          <Accordion
            open={open === 1}
            icon={
              <ChevronDownIcon
                strokeWidth={1.5}
                className={` h-4 w-4 transition-transform ${open === 1 ? "rotate-180 text-main" : "text-white"}`}
              />
            }
          >
            {/* Server selected */}
            <ListItem className="p-0 w-full bg-second hover:bg-basic border-2 border-basic" selected={open === 1} color='purple'>
              <AccordionHeader onClick={() => handleOpen(1)} className="w-full border-none px-3 py-2" color='purple'>
                <div className='w-full flex items-end'>
                  <div className="bg-third rounded-full w-8 h-8">
                    {serverSelected && servers.find(server => server.id === serverSelected)?.icon ? (
                      <img
                        src={`https://cdn.discordapp.com/icons/${serverSelected}/${servers.find(server => server.id === serverSelected).icon}`}
                        alt="Server Icon"
                        className="w-8 h-8 rounded-full"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full"></div>
                    )}
                  </div>
                  <div className='flex flex-col  pl-1'>
                    <span className='text-white pr-2 pl-1 text-base font-normal w-auto truncate transition-all duration-300 ease-in-out group-hover:whitespace-normal group-hover:overflow-visible'>
                      {servers?.find(server => server.id === serverSelected)?.name ||
                        <Spinner className="w-8 h-8 text-main/50" />
                      }
                    </span>
                    <span className='w-full border-b border-main animate-pulse'></span>
                  </div>
                </div>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="w-full py-px pt-2" >
              {/* List servers on bot */}
              <List className='p-0  max-h-60 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic' >
                {/* {servers?.map((server, index) => ( */}
                {servers?.filter(server => server.isBotInstalled).map((server, index) => (
                  <ListItem key={index} className='p-0 hover:bg-basic w-full' >
                    <Button className=' h-full w-full flex items-center  hover: transition-all duration-300 ease-in-out gap-x-2' variant='text' size='sm' onClick={() => handleChangeServer(server)}>
                      <div className="bg-basic rounded-full ">
                        {server.icon ? (
                          <img
                            src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                            alt="Server Icon"
                            className="w-8 h-8 rounded-full border border-basic shadow-dark shadow"
                          />
                        ) : (
                          <div className="w-8 h-8 rounded-full border border-basic shadow-dark shadow flex items-center justify-center text-white font-thin text-xs tracking-tighter">{server.name.substring(0, 3)}</div>
                        )}
                      </div>
                      <span className='text-white text-base font-normal w-auto truncate transition-all duration-300 ease-in-out group-hover:whitespace-normal group-hover:overflow-visible capitalize'>
                        {server.name}
                      </span>
                    </Button>
                  </ListItem>
                ))}
                {/* -------- map termina aqui -------- */}
              </List>
              <hr className="my-0 border-main mx-1" />
              {/* Call select server */}
              <ListItem className='p-0.5 hover:bg-basic group-hover:text-main transition-all duration-300 border-none shadow shadow-dark group' >
                <Button className='w-full h-full flex items-center gap-x-2 rounded-md' variant='text' size='sm' color='black' onClick={handleSelectServer}>
                  <BiPlusCircle size={28} className="group-hover:text-main text-white transition-all duration-300" />
                  <span className='w-full text-white tracking-wider group-hover:text-main transition-all duration-300'>
                    Add new server
                  </span>
                </Button>
              </ListItem>
            </AccordionBody>
          </Accordion>

        </List>
      </Card>
    </>
  );
}

export default DropdownChoose;