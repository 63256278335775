import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import './App.css'
import AntiBotMembers from './admin/AntiBotMembers/AntiBotMembers'
import ChooseMain from './admin/ChooseServer/Main'
import HolderVerification from './admin/HolderVerification/HolderVerification'
import AdminMain from './admin/Main'
import NotFound from './admin/NotFound/NotFound'
import StakingSetup from './admin/StakingSetup/StakingSetup'
import TokenCreation from './admin/TokenCreation/TokenCreation'
import ErrorPage from './components/Error/Error'
import MoreFeature from './components/Feature/MoreFeatures'
import Main from './components/Main/Main'
import ChooseServer from './admin/ChooseServer/ChooseServer'
import DecentralizedStorage from './admin/DecentralizedStorage/DecentralizedStorage'
import RevenueSharing from './admin/RevenueSharing/RevenueSharing'
import VotingSystem from './admin/VotingSystem/VotingSystem'
import { isAuthenticated } from './utils/authUtils'

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/allfeatures" element={<MoreFeature />} />
        <Route path="/select-server" element={<ChooseMain />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<AdminMain />} />}>
          <Route path="antiBotMembers" element={<ProtectedRoute element={<AntiBotMembers />} />} />
          <Route path="holderverification" element={<ProtectedRoute element={<HolderVerification />} />} />
          <Route path="stakingsetup" element={<ProtectedRoute element={<StakingSetup />} />} />
          <Route path="tokencreation" element={<ProtectedRoute element={<TokenCreation />} />} />
          <Route path="revenuesharing" element={<ProtectedRoute element={<RevenueSharing />} />} />
          <Route path="decentralizestorage" element={<ProtectedRoute element={<DecentralizedStorage />} />} />
          <Route path="votingsystem" element={<ProtectedRoute element={<VotingSystem />} />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/dashboard/not-found" />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  )
}

export default App
