import React from 'react'
import { Typography } from '@material-tailwind/react'
import { FaCube } from 'react-icons/fa'
import comingSoon from '../../img/comming-soon.png'

const Notice = () => {
  return (
    <>
      <div className='w-full' >
        <Typography variant="h6" className=" mb-1 w-full">
          Upgrade to Premium
        </Typography>
        <Typography variant="small" className="font-normal opacity-80">
          Upgrade to Tektools Premium and Make any NFT project safer, by giving all the necessary tools and easier to use experience.
        </Typography>
        <img src={comingSoon} alt="coming soon" className='absolute rounded-b-lg bottom-0 left-0' />
        <div className="mt-4 flex gap-3">
          <Typography
            as="a"
            href="#"
            variant="small"
            className="font-medium opacity-80"
            onClick={() => setOpenAlert(false)}
          >
            Dismiss
          </Typography>
          <Typography
            as="a"
            href="#"
            variant="small"
            className="font-bold text-main"
          >
            Upgrade Now
          </Typography>
        </div>
      </div>
    </>
  )
}

export default Notice