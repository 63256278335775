import React, { useState, useEffect } from 'react'
import { Button, Dialog } from '@material-tailwind/react'
import CreateHolderVerification from './CreateHolderVerification'
import LoadHolderVerification from './LoadHolderVerification'

const HolderVerification = () => {
  const [newCollection, setNewCollection] = useState(false)
  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [openNewCollection, setOpenNewCollection] = useState(false)

  //Check if the server is valid
  const [validServer, setValidServer] = useState(false)
  useEffect(() => {
    const checkServer = async () => {
      const guildId = localStorage.getItem('guildId');
      const myServers = JSON.parse(localStorage.getItem('myServers'));
      const server = myServers.find(server => server.id === guildId);
      if (server && server.isBotInstalled) {
        setValidServer(true);
      }
    };
    checkServer();
  }, []);

  //Dialog create new collection
  const handleDialogClose = () => {
    setOpenNewCollection(false)
  }

  return (
    <section className="text-white py-5">
      <div className="flex justify-between items-center container mx-auto pb-10">
        <h1 className="text-4xl font-bold">
          Holder Verification
        </h1>
        <div className='w-40 h-12'>
          {!newCollection && (
            <Button
              disabled={!validServer}
              onClick={() => setOpenNewCollection((cur) => !cur)}
              color="purple"
              className="h-full w-full font-bold px-2 py-3"
            >
              New Collection
            </Button>
          )}
        </div>
      </div>
      <LoadHolderVerification updateTrigger={updateTrigger} />
      {newCollection && (
        <CreateHolderVerification />
      )}
      <Dialog
        className="flex flex-col text-white bg-bgAdmin bg-no-repeat bg-opacity-25 bg-cover bg-right-bottom overflow-hidden h-[90vh] shadow-shadow border border-dark overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic"
        size="md"
        animate={{
          mount: { y: 0 },
          unmount: { y: -200 },
        }}
        open={openNewCollection}
        handler={handleDialogClose}
      >
        <CreateHolderVerification />
      </Dialog>
    </section>
  )
}

export default HolderVerification
