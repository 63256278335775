import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'


const animatedComponents = makeAnimated()
const SelectCollection = ({ onSelectCollection, qntSelect, isMulti }) => {
  const [collectionAddress, setCollectionAddress] = useState('')
  const [creatorAddress, setCreatorAddress] = useState('')
  const [collections, setCollections] = useState([])
  const [selectedCollections, setSelectedCollections] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const maxSelectedOptions = qntSelect
  const isValidNewOption = (inputValue, selectValue) => {
    return selectValue.length < maxSelectedOptions
  }


  useEffect(() => {
    const fetchCollections = async () => {
      setIsLoading(true)
      try {
        const apiUrlSelectCollection = `${process.env.REACT_APP_API_BASE_URL}/collections/?guildId=${localStorage.getItem('selectedServer')}`
        const response = await fetch(apiUrlSelectCollection)
        const data = await response.json()
        setCollections(data)
      } catch (error) {
        console.error('Erro ao buscar collections:', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (collections.length === 0) {
      fetchCollections()
    }
  }, [])

  const dropdownOptions = collections.map((collection) => ({
    value: collection.VerifiedAddress,
    label: `${collection.DisplayName} - ${collection.VerifiedAddress} `,
  }))

  const handleSelectCollections = (selectedOptions) => {
    if (selectedOptions.length > maxSelectedOptions) {
      selectedOptions = selectedOptions.slice(0, maxSelectedOptions)
    }
    setSelectedCollections(selectedOptions)
    onSelectCollection(selectedOptions)
  }

  return (
    <div className="flex flex-col w-full gap-y-1">
      <span className="px-3 text-base font-thin">Select collection</span>
      <Select
        placeholder="Select collection"
        isLoading={isLoading}
        isValidNewOption={isValidNewOption}
        components={animatedComponents}
        isMulti={isMulti}
        options={dropdownOptions}
        value={selectedCollections}
        onChange={handleSelectCollections}
        styles={{
          valueContainer: (provided, state) => ({
            ...provided,
            padding: state.hasValue ? '0.5rem 0' : '0.5rem',
          }),
          container: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            border: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
          }),
          control: (provided, state) => ({
            ...provided,
            height: 48,
            maxWidth: '100%',
            backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
            color: '#fff',
            borderRadius: 8,
            borderColor: state.isFocused ? '#9945ff' : '#fff',
            boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
            paddingLeft: '1rem',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#9945ff'
              : state.isFocused
                ? '#9945ff'
                : '#393D5E',
            color: '#fff',
            cursor: 'pointer',
            padding: 0,
            paddingLeft: '1rem',
            margin: 0,
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#9945ff',
            color: '#fff',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
          }),
        }}
      />
    </div>
  )
}

export default SelectCollection
