import React, { useState, useEffect } from 'react'
import { Button, Dialog } from '@material-tailwind/react'
import LoadStakingSetup from './LoadStakingSetup'
import CreateStakingSetup from './CreateStakingSetup'

const Staking = () => {
  const [stakingList, setStakingList] = useState([])
  const [close, setClose] = useState(false)

  const handleClose = () => {
    setClose(!close)
  }

  return (
    <div className="container mx-auto h-full text-white py-5">
      <div className="flex flex-col gap-y-5">
        <div className="flex justify-between items-start">
          <h1 className="text-4xl pb-14 font-bold">Staking Setup</h1>
          <div className='w-40 h-12'>
            <Button
              color="purple"
              className="h-full w-full font-bold px-2 py-3"
              onClick={handleClose}
            >
              New Staking
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-full items-center justify-center gap-y-5">
          <LoadStakingSetup staking={stakingList} setStaking={setStakingList} />

          <Dialog
            open={close}
            handler={handleClose}
            className="flex flex-col h-[90vh] text-white bg-bgAdmin overflow-y-scroll overflow-hidden "
            size="lg"
            animate={{
              mount: { y: 0 },
              unmount: { y: -200 },
            }}
          >
            <CreateStakingSetup />
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export default Staking
