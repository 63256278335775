import { AiTwotoneUsb, AiOutlineLogout, AiFillDatabase } from "react-icons/ai";
import { FiUserCheck } from "react-icons/fi";
import { BsArrowLeftRight } from "react-icons/bs";
import { MdOutlineToken, MdHowToVote, MdLockOutline, MdOutlineAccountBalanceWallet } from "react-icons/md";

const dataSidebar = [
    {
        name: 'Anti Bot Members',
        icon: <AiTwotoneUsb />,
        link: 'antibotmembers',
        tip: 'Consectetur voluptate veniam sunt magna mollit.',
        enable: true
    },
    {
        name: 'Holder Verification',
        icon: <FiUserCheck />,
        link: 'holderverification',
        tip: 'Nulla excepteur Lorem ex ea commodo fugiat eu amet nisi reprehenderit esse dolor.',
        enable: true
    },
    {
        name: 'Staking Setup',
        icon: <BsArrowLeftRight />,
        link: 'stakingsetup',
        tip: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
        enable: false
    },
    {
        name: 'SPL - Token Creation',
        icon: <MdOutlineToken />,
        link: 'TokenCreation',
        tip: 'Tip for Token Creation',
        enable: false
    },
    {
        name: 'Revenue Sharing',
        icon: <AiOutlineLogout />,
        link: 'RevenueSharing',
        tip: 'Tip for Revenue Sharing',
        enable: false
    },
    {
        name: 'Decentralized Store',
        icon: <AiFillDatabase />,
        link: 'DecentralizeStorage',
        tip: 'Tip for Decentralized Store',
        enable: false
    },
    {
        name: 'Voting System',
        icon: <MdHowToVote />,
        link: 'VotingSystem',
        tip: 'Tip for Voting System',
        enable: false
    },
    {
        name: 'Password Lock',
        icon: <MdLockOutline />,
        link: 'PasswordLock',
        tip: 'Tip for Password Lock',
        enable: false
    },
    {
        name: 'Wallet Management',
        icon: <MdOutlineAccountBalanceWallet />,
        link: 'WalletManagement',
        tip: 'Tip for Wallet Management',
        enable: false
    }
]

export default dataSidebar;