import { BiChevronDown, BiTrash } from "react-icons/bi";
import React, { useEffect, useState } from 'react'
import { Button, Card, Dialog, Input, Spinner, Switch, DialogHeader, DialogBody, DialogFooter } from '@material-tailwind/react'
import { BiEdit, BiSearchAlt2 } from 'react-icons/bi'
import EditHolderVerification from './EditHolderVerification'
import EditHolderVerification2 from './EditHolderVerification copy'
import SendInstructions from "./_components/SendInstructions";
import { toast } from "react-toastify";


const LoadHolderVerification = ({ updateTrigger }) => {
  const guildId = localStorage.getItem('guildId')
  const [holderList, setHolderList] = useState([])
  const [close, setClose] = useState(false)
  const [openSendInstructions, setOpenSendInstructions] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const [selectedCard2, setSelectedCard2] = useState(null)
  const [serverList, setServerList] = useState([])
  const [search, setSearch] = useState('')
  const [validServer, setValidServer] = useState(false)
  const [openOptionsCard, setOpenOptionsCard] = useState(null)
  const [selectedCollection, setSelectedCollection] = useState(null)


  useEffect(() => {
    const checkServer = () => {
      const guildId = localStorage.getItem('guildId');
      const myServers = JSON.parse(localStorage.getItem('myServers'));
      const server = myServers.find(server => server.id === guildId);
      if (server && server.isBotInstalled) {
        setValidServer(true);
      }
    };
    checkServer();
  }, []);

  const handleCardClick = (holder, collection) => {
    setSelectedCard({ holder, collection })
    handleClose()
  }

  const handleClose = () => {
    setClose((cur) => !cur)
  }

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const handleEditCard = (collection) => {
    setSelectedCollection(collection);
    setOpenEditDialog(true)
  }

  const handleCloseCardEdit = () => {
    setOpenEditDialog(false)
    setSelectedCollection(null)
  }

  const handleSendInstructions = () => {
    setOpenSendInstructions((current) => !current);
  }

  const handleSendInstructionsClose = () => {
    setOpenSendInstructions(false);
  }

  const updateLoadCollection = () => {
    const HolderList = async () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/collections?guildId=${guildId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setHolderList(data)
        })
    }
    const ServerList = async () => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/roles/${guildId}`)
        .then((res) => res.json())
        .then((data) => {
          setServerList(data)
        })
    }
    HolderList()
    ServerList()
  }

  useEffect(() => {
    updateLoadCollection()
  }, [updateTrigger])


  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (collection) => {
    setSelectedCollection(collection);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCollection(null);
  };

  const disableCollection = async () => {
    if (!selectedCollection) return;

    const infoDisable = {
      guildId: localStorage.getItem('guildId'),
      collectionAddress: selectedCollection.CollectionAddress,
      disable: true
    };
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/disable`;
    try {
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoDisable),
      });
      if (!response.ok) {
        throw new Error('Failed to disable collection');
      }
      const data = await response.json();
      toast.success('Collection disabled successfully!');
      updateLoadCollection();
    } catch (error) {
      console.error('Error disabling collection:', error);
      toast.error(`Opss, something gone wrong: ${error.message}`);
    } finally {
      handleCloseDialog();
    }
  };

  const disableCollectionById = async () => {
    if (!selectedCollection) return;

    const infoDisable = {
      guildId: localStorage.getItem('guildId'),
      collectionId: selectedCollection._id,
      disable: true
    };
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/disable-by-id`;
    try {
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoDisable),
      });
      if (!response.ok) {
        throw new Error('Failed to disable collection');
      }
      const data = await response.json();
      toast.success('Collection disabled successfully!');
      updateLoadCollection();
    } catch (error) {
      console.error('Error disabling collection:', error);
      toast.error(`Opss, something gone wrong: ${error.message}`);
    } finally {
      handleCloseDialog();
    }
  };

  const [openConfirm, setOpenConfirm] = useState(false)
  const confirmClose = () => {
    setOpenConfirm(true)
  }
  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  return (
    <>
      <section className='container mx-auto flex flex-col gap-y-5'>
        <div className='relative w-full' >
          {/* Filter  */}
          <div className='flex flex-row justify-between '>
            <div className='w-full max-w-96 '>
              <Input
                color='purple'
                placeholder='Search by name or address'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className='w-full text-white '
                icon={<BiSearchAlt2 className='text-white active:text-main ' />}
                variant="static"
                containerProps={{
                  className: 'text-white'
                }}
              />
            </div>
            {/* Acction Button */}
            <div className='flex flex-row gap-x-px bg-accentMain hover:bg-accentHouver rounded-lg transition-all duration-700'>
              <Button
                color="deep-purple"
                className="flex flex-row items-center justify-center text-center gap-x-2 w-32 h-12 text-xs font-bold -tracking-wide p-2  bg-accentMain transition-all duration-300 hover:bg-accentHouver rounded-rl " onClick={() => handleSendInstructions()}>
                Send instructions
              </Button>
              <div className='h-full w-px bg-third'></div>
              <Button color="deep-purple" className={`w-7 flex items-center justify-center transition-transform  bg-accentMain hover:bg-accentHouver px-1 rounded-l ${openOptionsCard ? 'rotate-180 ' : ''}`}
              // onClick={() => handleOptions()}
              ><BiChevronDown strokeWidth={1.5} /></Button>
            </div>
          </div>
          {/* Implementar lista com ações aqui */}
          {openOptionsCard && (
            <div className={`absolute -bottom-96 right-0 mt-2 w-72 h-96 rounded-lg z-50 bg-dark shadow-xl shadow-main/25 transform ease-in-out transition-all duration-700 opacity-100 scale-100`}>
              teste123
            </div>
          )}
          {/* Send Instructions */}
          {openSendInstructions &&
            <div className={`absolute bottom-0 right-40 mt-2 w-96 rounded-lg z-50 bg-third p-2 shadow-xl shadow-main/25 transform ease-in-out transition-all duration-700 opacity-100 scale-100`}>
              <SendInstructions onClose={handleSendInstructionsClose} />
            </div>
          }
        </div>
        <div className="flex flex-wrap gap-5 overflow-y-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic pb-10 items-center justify-center">
          {/* Skeleton */}
          {!validServer && (
            <Card className="h-60 w-80 group overflow-hidden transition-opacity hover:opacity-90 bg-second">
              <div className="absolute w-full h-full flex items-center justify-center">
                <Spinner className="w-12 h-12 text-main/10" />
              </div>
              <div className="w-full h-full flex flex-row bg-basic rounded-lg">
                <div className="w-4/5 h-full flex flex-col bg-second shadow-md rounded-lg gap-x-2 transition-all duration-300"></div>
              </div>
            </Card>
          )}
          {/* Card loaded with staking list */}
          {Array.isArray(holderList) &&
            holderList.length > 0 &&
            holderList
              ?.flatMap(holder => 
                holder?.CollectionNotifications
                  .filter(collection =>
                    (collection?.DisplayName?.toLowerCase().includes(search?.toLowerCase()) ||
                      collection?.VerifiedAddress?.toLowerCase().includes(search?.toLowerCase())) &&
                    collection.IsEnabled
                  )
                  .slice().reverse().map((collection, collectionIndex) => ({ holder, collection, collectionIndex }))
              )
              .map(({ holder, collection, collectionIndex }, holderIndex) => (
                <Card
                  className="h-60 w-80 group overflow-hidden transition-opacity hover:opacity-90 bg-second" key={`${holderIndex}-${collectionIndex}`}
                >
                  <div
                    className="w-full h-full flex flex-row bg-basic rounded-lg">
                    {/* Esquerda */}
                    <div className="w-4/5 h-full flex flex-col bg-second shadow-md rounded-lg gap-x-2 transition-all duration-300">
                      {/* Collection name */}
                      <div className="w-full flex flex-row items-end gap-x-5 pt-5 pl-5">
                        <span className="text-xl font-bold border-b-2 border-basic transition-all w-full pl-5">
                          {collection.DisplayName}
                        </span>
                      </div>
                      <div className="w-full h-full flex flex-col items-end justify-start gap-y-5">
                        <div className="w-20 h-2 flex text-center items-center justify-center bg-basic rounded-bl-lg -mr-1 group-hover:w-44 transition-all duration-300"></div>
                        <div className="w-full h-full flex flex-row">
                          <div className="flex flex-col w-full items-center justify-center gap-y-5 pl-5">
                            {/* Creator Address id */}
                            <div className="flex flex-col w-full gap-x-2">
                              {collection.IsToken ? (
                                <h3 className="text-base">Token Address:</h3>
                              ) : (
                                <h3 className="text-base">
                                  Trait Collection Address:
                                </h3>
                              )}
                              <h4 className="text-sm truncate pr-2 transition-all duration-300">
                                {collection.VerifiedAddress}
                              </h4>
                            </div>

                            {/*  Holder Role */}
                            <div className="flex flex-row w-full items-center">
                              <span className="text-sm flex gap-x-2">
                                Holder role{' '}
                                {
                                  Array.isArray(serverList) ? serverList.find(
                                    (server) => server.id === collection.HolderRole
                                  )?.name : null
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row w-full gap-x-2 items-start pl-5 pb-5">
                        <Button
                          color="deep-purple"
                          className="flex flex-row items-center justify-center text-center gap-x-2 w-24 h-10 text-base font-bold px-2  bg-main transition-all duration-300 hover:bg-mainHover"
                          onClick={() => handleCardClick(holder, collection)}
                        >
                          <BiEdit className="text-lg" />
                          Edit
                        </Button>
                        <Button
                          color="purple"
                          className="hidden flex-row items-center justify-center text-center gap-x-2 w-24 h-10 text-base font-bold px-2  bg-accentMain transition-all duration-300 hover:bg-mainHover"
                          onClick={() => handleEditCard(collection)}
                        >
                          <BiEdit className="text-lg" />
                          Edit 2
                        </Button>
                      </div>
                    </div>

                    {/* ______________________________________________________________________________________________ */}

                    {/* Direita */}
                    <div className="w-1/5 h-full flex flex-col items-start pl-3 justify-center gap-y-5 transition-all duration-300 relative">
                      <div className="absolute top-2 right-4">
                        <Button
                          color="red"
                          variant="text"
                          className="text-sm font-bold p-2  bg-transparent transition-all duration-300  border-none active:border-none"
                          onClick={() => handleOpenDialog(collection)}
                        >
                          <BiTrash className="text-lg" />
                        </Button>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-y-1">
                        <span className="text-sm">Sales</span>
                        {collection.Sales ? (
                          <Switch
                            className={`${collection.Sales ? 'bg-main' : 'bg-third'}`}
                            checked={true}
                            onChange={() => { }}
                          />
                        ) : (
                          <Switch
                            className={`${collection.Sales ? 'bg-main' : 'bg-third'}`}
                            checked={false}
                            onChange={() => { }}
                          />
                        )}
                      </div>

                      <div className="flex flex-col items-center justify-center gap-y-1">
                        <span className="text-sm">Listing</span>
                        {collection.Listing ? (
                          <Switch
                            className={`${collection.Listing ? 'bg-main' : 'bg-third'}`}
                            checked={true}
                            onChange={() => { }}
                          />
                        ) : (
                          <Switch
                            className={`${collection.Listing ? 'bg-main' : 'bg-third'}`}
                            checked={false}
                            onChange={() => { }}
                          />
                        )}
                      </div>

                    </div>
                  </div>
                </Card>

              ))}
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            className="text-white bg-bgAdmin"
            size="xs"
          >
            <DialogHeader className="text-white uppercase flex flex-col items-start">
              <div className="pl-px">Delete Collection</div>
              <hr className="w-full border-basic" />
            </DialogHeader>
            <DialogBody className="text-white">
              Are you sure you want to delete this collection {selectedCollection?.DisplayName}?
            </DialogBody>
            <DialogFooter className="flex flex-row gap-x-2">
              <Button onClick={handleCloseDialog} variant="text" color="red">
                Cancel
              </Button>
              <Button onClick={disableCollectionById} color="purple" autoFocus>
                Confirm
              </Button>
            </DialogFooter>

          </Dialog >
          {/* Staking Editp dialog */}
          < Dialog
            open={close}
            handler={handleClose}
            className="flex flex-col  text-white bg-bgAdmin overflow-y-scroll overflow-hidden"
            size="lg"
            animate={{
              mount: { y: 0 },
              unmount: { y: -200 },
            }}
          >
            <div className=" rounded-lg">
              <EditHolderVerification
                loadCollection={holderList}
                selectedCard={selectedCard}
                updateLoadCollection={updateLoadCollection}
              />
            </div>
          </Dialog>

          {/* ____________________________________ */}
          < Dialog
            open={openEditDialog}
            onClose={handleCloseCardEdit}
            handler={confirmClose}
            className="flex flex-col  text-white bg-bgAdmin overflow-y-scroll overflow-hidden"
            size="xl"
          >
            <DialogBody>
              <EditHolderVerification2
                loadCollection={holderList}
                selectedCard={selectedCollection}
                updateLoadCollection={updateLoadCollection}
              />
            </DialogBody>
            <DialogFooter className="flex flex-row gap-x-2">
              <Button onClick={handleCloseCardEdit} variant="text" color="red">
                Cancel
              </Button>
              <Button onClick={() => console.log(selectedCollection)} color="purple" autoFocus>
                Confirm
              </Button>
            </DialogFooter>
          </Dialog>
          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            className="text-white bg-bgAdmin"
            size="xs"
          >
            <DialogHeader className="text-white uppercase flex flex-col items-start gap-y-2">
              <div className="pl-px">Cancel Edit </div>
              <hr className="w-full border-basic" />
            </DialogHeader>
            <DialogFooter className="flex flex-row gap-x-2">
              <Button onClick={() => setOpenConfirm(false)} variant="text" color="red">
                Cancel
              </Button>
              <Button onClick={() => { handleCloseCardEdit(); setOpenConfirm(false) }} color="purple" autoFocus>
                Confirm
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      </section>

    </>
  )
}

export default LoadHolderVerification