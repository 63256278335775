import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const MultiselectRole = ({
  onSelectRole,
  qntSelect,
  isMulti,
  variant,
  noLabel,
}) => {
  const animatedComponents = makeAnimated()
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const maxSelectedOptions = qntSelect
  const isValidNewOption = (inputValue, selectValue) => {
    return selectValue.length < maxSelectedOptions
  }
  //chamada API para listar as Roles
  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true)
      try {
        const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
        const response = await fetch(apiUrlSelectRole)
        const data = await response.json()
        setRoles(data)
      } catch (error) {
        console.error('Erro ao buscar roles:', error)
        // Lide com o erro de acordo com a sua aplicação
      } finally {
        setIsLoading(false)
      }
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [])
  const dropdownOptions = roles?.length > 0 ? roles?.map((role) => ({
    value: role.id,
    label: role.name,
  })) : [{value: 'none', label: 'No roles found'}]

  //seleciona multiselect
  const handleSelectRole = (selectedOptions) => {
    if (selectedOptions.length > maxSelectedOptions) {
      selectedOptions = selectedOptions.slice(0, maxSelectedOptions)
    }
    setSelectedRoles(selectedOptions)
    onSelectRole(selectedOptions)
  }

  return (
    <>
      {!noLabel && (
        <span className="px-3 text-base font-thin">Select roles</span>
      )}
      <Select
        placeholder="Select roles"
        isLoading={isLoading}
        isValidNewOption={isValidNewOption}
        components={animatedComponents}
        isMulti={isMulti}
        isClearable
        options={dropdownOptions}
        value={selectedRoles}
        onChange={handleSelectRole}
        className="basic-multi-select"
        classNamePrefix="select"
        styles={{
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#393D5E',
            color: '#fff',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: state.hasValue ? '0.5rem 0' : '0.5rem',
            height: 'auto',
            maxHeight: '100%',
          }),
          container: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            border: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
          }),
          control: (provided, state) => ({
            ...provided,
            height: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
            backgroundColor: state.isFocused ? '#393D5E' : '#4E537B',
            color: '#fff',
            borderRadius: 8,
            borderColor: state.isFocused ? '#9945ff' : '#fff',
            boxShadow: state.isFocused ? '0 0 0 1px #9945ff' : '#9945ff',
            paddingLeft: '1rem',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#9945ff'
              : state.isFocused
                ? '#9945ff'
                : '#393D5E',
            color: '#fff',
            cursor: 'pointer',
            padding: 0,
            paddingLeft: '1rem',
            margin: 0,
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#9945ff',
            color: '#fff',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
          }),
        }}
      />
    </>
  )
}

export default MultiselectRole
