import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Spinner, Button } from '@material-tailwind/react'
import { toast, ToastContainer } from 'react-toastify'
import AvatarImage from '../../../img/TektoolsFaceWhite.png'
import { useNavigate } from 'react-router-dom'

const ServerCard = ({ serversList }) => {
  const navigate = useNavigate()
  const [servers, setServers] = useState([])
  const [loading, setLoading] = useState(false)
  const [serverName, setServerName] = useState('')

  //Save servers list in state
  useEffect(() => {
    setServers(serversList)
  }, [serversList])

  //Save server in local storage and navigate to dashboard
  const handleBotton = (server) => {
    localStorage.setItem('myServers', JSON.stringify(servers))
    if (server.isBotInstalled) {
      setServerName(server.name)
      setLoading(true)
      localStorage.setItem('selectedServer', server.id)
      localStorage.setItem('guildId', server.id)
      setTimeout(() => {
        navigate('/dashboard')
      }, 500)
      toast.info(`${server.name} has been selected`, {
        onClose: () => {
          setLoading(false)
          navigate('/dashboard')
        }
      })
    }
    //Aplicar logica para abrir o link de autorização do discord
    else {
      const newWindow = window.open(
        'https://discord.com/oauth2/authorize?client_id=940332206512033894&permissions=8&scope=bot',
        'popup',
        'width=600,height=600,scrollbars=no,resizable=no,return=true'
      )
      newWindow.focus()
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {loading && (
        <div className="absolute top-0 left-0 w-screen h-screen bg-dark/40 z-40 flex items-center justify-center gap-x-2">
          <span>{`Loading ${serverName}...`}</span>
          <Spinner className="w-12 h-12 text-main/50" />
        </div>
      )}
      {servers?.length === 0 || servers === null ? (
        <div className="absolute top-0 left-0 w-screen h-screen bg-dark/40 z-40 flex items-center justify-center">
          <Spinner className="w-12 h-12 text-main/50" />
        </div>
      ) : (
        <div>
          {!loading && <div className='flex flex-wrap gap-4 justify-center'>
            {servers?.map((server, index) => (
              <Card className='group overflow-hidden w-80 h-80 bg-second rounded-xl' key={index}>
                {/* Card Header - Avatar/Icon */}
                <div className='p-0'>
                  <div className="relative w-full h-[220px] flex items-center justify-center overflow-hidden bg-dark">
                    {server.icon === null ? (
                      <div
                        className="absolute inset-0 bg-main bg-no-repeat bg-center bg-cover p-1"
                        style={{
                          backgroundImage: `url(${AvatarImage})`, filter: 'blur(10px)',
                          opacity: '0.5', backgroundSize: '170%'
                        }}
                      ></div>
                    ) : (
                      <div
                        className="absolute inset-0 bg-main bg-no-repeat bg-center bg-cover p-1"
                        style={{
                          backgroundImage: `url(https://cdn.discordapp.com/icons/${server.id}/${server.icon})`, filter: 'blur(10px)',
                          opacity: '0.5', backgroundSize: '170%'
                        }}
                      ></div>
                    )}
                    {server.icon === null ? (
                      <div className="w-[120px] h-[120px] z-10 rounded-full border border-second shadow shadow-dark bg-second flex items-center justify-center">
                        <span className="text-white text-2xl font-bold">
                          {server.name.substring(0, 6)}
                        </span>
                      </div>
                    ) : (
                      <img src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                        alt={server.name} className="w-[120px] h-[120px] z-10 rounded-full border border-second shadow shadow-dark" />
                    )}
                  </div>
                </div>
                {/* Card Body - Server Name and type permission */}
                <CardBody className='py-auto'>
                  <div className='flex w-full justify-between'>
                    <div className='flex flex-col w-40 group-hover:w-full justify-center '>
                      <span className='text-white w-auto truncate transition-all duration-300 ease-in-out group-hover:whitespace-normal group-hover:overflow-visible'>
                        {server.name}
                      </span>
                      <span className='text-white/50 text-sm w-auto'>
                        {server.owner ? 'Owner' : 'Bot Master'}
                      </span>
                    </div>
                    {/* Action Button */}
                    <div className='w-20 h-12 shadow shadow-dark/30 absolute right-5'>
                      <Button className='h-full w-full' color={server.isBotInstalled ? 'purple' : 'deep-purple'} size='sm' onClick={() => handleBotton(server)}>
                        {server.isBotInstalled ? 'GO' : 'Setup'}
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>}
        </div>
      )}
    </>
  )
}

export default ServerCard