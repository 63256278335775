import { Button, Input } from '@material-tailwind/react'
import React, { useState, useEffect, useRef } from 'react'
import { BiMinus, BiPlus, BiX } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify'
import coin from '../../img/coin.png'
import RoleableStaked from './_components/RoleableStaked'
import StakedCollection from './_components/StakedCollection'
import TraitRewards from './_components/TraitRewards'

const EditStakingSetup = ({ listStaking, setOpenEditStaking }) => {

  const dataStaking = listStaking?.map((staking) => {
    return {
      id: staking._id,
      GuildId: staking.GuildId,
      RoleAbleToStake: staking.RoleAbleToStake,
      PointSystemName: staking.PointSystemName,
      StakedCollection: staking.StakedCollection,
      StakingChannelId: staking.StakingChannelId,
      TokenAddress: staking.TokenAddress,
      TokenName: staking.TokenName,
      TraitBased: staking.TraitBased,
      UsesPointSystem: staking.UsesPointSystem,
      TotalRewardPerDay: staking.TotalRewardPerDay,
      WithdrawMinimumBalance: staking.WithdrawMinimumBalance,
      WithdrawMinimumDays: staking.WithdrawMinimumDays,
      TraitBased: staking.TraitBased,
      traitRewardList: staking.StakedCollection.flatMap(
        (collection) => collection.TraitRewards
      ),
    }
  })

  const rolesLoadedRef = useRef(false); // Usar useRef para rastrear se já carregou

  const [selectedRole, setSelectedRole] = useState(null)

  const [open, setOpen] = useState(false)
  const [openTable, setOpenTable] = useState(false)

  const [newTrait, setNewTrait] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [tokenName, setTokenName] = useState(
    dataStaking.map((staking) => staking.TokenName)
  )
  const [tokenAddress, setTokenAddress] = useState(
    dataStaking.map((staking) => staking.TokenAddress)
  )
  const [collectionAddress, setCollectionAddress] = useState(null)
  const handleCollectionAddress = (address) => {
    setCollectionAddress(address)
  }

  const handleCancel = () => {
    setOpenEditStaking(false)
  }

  // Reward per day
  const [valueTotalRewardPerDay, setValueTotalRewardPerDay] = useState(
    dataStaking[0]?.TotalRewardPerDay || 0
  )
  const incrementTotalRewardPerDay = () =>
    setValueTotalRewardPerDay((prevValue) => prevValue + 1)
  const decrementTotalRewardPerDay = () =>
    setValueTotalRewardPerDay((prevValue) =>
      prevValue <= 1 ? 1 : prevValue - 1
    )

  // WithdrawMinimumBalance
  const [valueWithdrawMinimumBalance, setValueWithdrawMinimumBalance] =
    useState(dataStaking[0]?.WithdrawMinimumBalance || 0)
  const incrementWithdrawMinimumBalance = () =>
    setValueWithdrawMinimumBalance((prevValue) => prevValue + 1)
  const decrementWithdrawMinimumBalance = () =>
    setValueWithdrawMinimumBalance((prevValue) =>
      prevValue <= 1 ? 1 : prevValue - 1
    )

  // WithdrawTime
  const [valueWithdrawMinimumDays, setValueWithdrawMinimumDays] = useState(
    dataStaking[0]?.WithdrawMinimumDays || 0
  )
  const incrementWithdrawMinimumDays = () =>
    setValueWithdrawMinimumDays((prevValue) => prevValue + 1)
  const decrementWithdrawMinimumDays = () =>
    setValueWithdrawMinimumDays((prevValue) =>
      prevValue <= 1 ? 1 : prevValue - 1
    )

  // RoleAbleToStake
  const [roleAbleToStake, setRoleAbleToStake] = useState(
    dataStaking.map((staking) => staking.RoleAbleToStake)
  )
  const handleRoleAbleToStakeUpdate = (newRoleAbleToStake) => {
    setRoleAbleToStake(newRoleAbleToStake)
  }

  // loadCollections
  const [loadCollections, setLoadCollections] = useState(
    dataStaking.map((staking) => staking.StakedCollection)
  )

  // Rewards
  const [collections, setCollections] = useState([])
  const handleCollectionsUpdate = (newCollections) => {
    setCollections(newCollections)
  }

  const updateStakingSetup = async () => {
    const updateData = {
      guildId: dataStaking[0].GuildId,
      tokenName: tokenName[0],
      tokenAddress: tokenAddress[0],
      dailyRewardAmount: valueTotalRewardPerDay,
      minimumWithdrawalBalance: valueWithdrawMinimumBalance,
      minimumWithdrawalDays: valueWithdrawMinimumDays,
      stakingCollectionAddress: collectionAddress,
      stakingChannelId: dataStaking[0].StakingChannelId,
      eligibleRole: roleAbleToStake.map((role) => role.value),
      includeTraitSpecificRewards: dataStaking[0].TraitBased,
      traitRewardList: collections,
      includeRewardDetails: true,
    }
    console.log('updateData', updateData)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/staking/edit`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateData),
        }
      )
      if (!response.ok) {
        throw new Error('Failed to update staking')
      }
      const data = await response.json()
      toast.success('Staking updated successfully')
    } catch (error) {
      console.error('Error updating staking:', error)
      toast.error('Error updating staking', error)
    }
  }

  return (
    <>
      {/* Header */}
      <div className="w-full h-16 flex flex-col">
        <div className="w-full h-full bg-third">
          <h1 className="text-2xl font-bold h-full flex items-end pl-14">
            Edit Staking Setup
          </h1>
        </div>
        <div className="w-full flex">
          <div className="w-1/3 h-4 bg-third rounded-br "></div>
        </div>
      </div>
      {/* Button close X */}
      <div className="absolute top-0 right-0  items-center justify-center flex ">
        <Button
          className="text-2xl"
          variant="text"
          color="red"
          size="sm"
          onClick={() => handleCancel()}>
          <BiX />
        </Button>
      </div>
      {/* -------------------------------- */}
      {/* Body */}
      <section className="max-h-[80vh] w-full container mx-auto transition-all duration-300 overflow-y-auto rounded-lg scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
        <div className="w-full h-full flex lg:flex-row flex-col lg:gap-x-4 gap-y-4 py-4">
          {/* esquerda */}
          <div className="flex flex-col lg:w-3/5 w-full h-full rounded-lg gap-5 bg-basic">
            {/* Token information */}
            <div className="w-full p-2 flex  ">
              {/* Token image */}
              <div className="flex items-center justify-center">
                <img
                  src={coin}
                  alt="coin"
                  className="w-full h-32 object-cover"
                />
              </div>
              {/* Token details */}
              <div className="flex-1 flex flex-col gap-y-2 justify-center pl-5">
                {/* token address */}
                <div className="w-full lg:flex flex-1 justify-end">
                  <span className="flex lg:w-1/3 items-end  font-semibold">
                    Token Address
                  </span>
                  <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                    <Input
                      placeholder="Enter the token address here*"
                      color="deep-purple"
                      variant="static"
                      className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                      size="lg"
                      containerProps={{
                        className: 'bg-second ',
                      }}
                      labelProps={{
                        className:
                          'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                        style: {
                          color: 'white',
                        },
                      }}
                      value={tokenAddress}
                      onChange={(e) => setTokenAddress(e.target.value)}
                    />
                  </div>
                </div>
                {/* token name */}
                <div className="w-full lg:flex flex-1 justify-end">
                  <span className="flex lg:w-1/3 items-end  font-semibold">
                    Token Name
                  </span>
                  <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                    <Input
                      placeholder="Enter the name of token*"
                      color="deep-purple"
                      variant="static"
                      className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                      size="lg"
                      containerProps={{
                        className: 'bg-second ',
                      }}
                      labelProps={{
                        className:
                          'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                        style: {
                          color: 'white',
                        },
                      }}
                      value={tokenName}
                      onChange={(e) => setTokenName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Staking conditions */}
            <div className="w-full flex flex-col lg:grid lg:grid-cols-2 lg:gap-5 gap-y-5 p-2  ">
              <div className="rounded-lg">
                {/* Reward per day */}
                <div className="bg-third rounded-lg border border-second shadow">
                  <div className="font-semibold p-2">Reward per day</div>
                  <div className="flex items-end w-full h-full transition duration-300 ">
                    <div className="bg-third">
                      <Button
                        onClick={decrementTotalRewardPerDay}
                        className="bg-mainHover p-2 h-12 w-12 flex items-center justify-center"
                      >
                        <BiMinus />
                      </Button>
                    </div>
                    <div className="w-full bg-second h-full">
                      <Input
                        placeholder="Example: "
                        color="deep-purple"
                        variant="static"
                        className=" focus:pl-5 w-full h-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none "
                        size="lg"
                        containerProps={{
                          className: 'bg-second ',
                        }}
                        labelProps={{
                          className:
                            'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                          style: {
                            color: 'white',
                          },
                        }}
                        min={1}
                        value={valueTotalRewardPerDay}
                        onChange={(e) =>
                          setValueTotalRewardPerDay(parseInt(e.target.value))
                        }
                      />
                    </div>
                    <div className="bg-third">
                      <Button
                        variant="gradient"
                        onClick={incrementTotalRewardPerDay}
                        className="bg-mainHover p-2 h-12 w-12 flex items-center justify-center"
                      >
                        <BiPlus />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg">
                {/* Withdraw minimum balance */}
                <div className="bg-third  rounded-lg border border-second shadow">
                  <div className="font-semibold p-2">
                    Withdraw minimum balance
                  </div>
                  <div className="flex items-end w-full h-full transition duration-300 ">
                    <div className="bg-third">
                      <Button
                        onClick={decrementWithdrawMinimumBalance}
                        className="bg-mainHover p-2 h-12 w-12 flex items-center justify-center"
                      >
                        <BiMinus />
                      </Button>
                    </div>
                    <div className="w-full bg-second h-full">
                      <Input
                        placeholder="Example: "
                        color="deep-purple"
                        variant="static"
                        className=" focus:pl-5 w-full h-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none "
                        size="lg"
                        containerProps={{
                          className: 'bg-second ',
                        }}
                        labelProps={{
                          className:
                            'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                          style: {
                            color: 'white',
                          },
                        }}
                        min={1}
                        value={valueWithdrawMinimumBalance}
                        onChange={(e) =>
                          setValueWithdrawMinimumBalance(
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </div>
                    <div className="bg-third">
                      <Button
                        variant="gradient"
                        onClick={incrementWithdrawMinimumBalance}
                        className="bg-mainHover p-2 h-12 w-12 flex items-center justify-center"
                      >
                        <BiPlus />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg">
                {/* Withdraw minimum days */}
                <div className="bg-third  rounded-lg border border-second shadow">
                  <div className="font-semibold p-2">Withdraw minimum days</div>
                  <div className="flex items-end w-full h-full transition duration-300 ">
                    <div className="bg-third">
                      <Button
                        onClick={decrementWithdrawMinimumDays}
                        className="bg-mainHover p-2 h-12 w-12 flex items-center justify-center"
                      >
                        <BiMinus />
                      </Button>
                    </div>
                    <div className="w-full bg-second h-full">
                      <Input
                        placeholder="Example: "
                        color="deep-purple"
                        variant="static"
                        className=" focus:pl-5 w-full h-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none "
                        size="lg"
                        containerProps={{
                          className: 'bg-second ',
                        }}
                        labelProps={{
                          className:
                            'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                          style: {
                            color: 'white',
                          },
                        }}
                        min={0}
                        value={valueWithdrawMinimumDays}
                        onChange={(e) =>
                          setValueWithdrawMinimumDays(parseInt(e.target.value))
                        }
                      />
                    </div>
                    <div className="bg-third">
                      <Button
                        variant="gradient"
                        onClick={incrementWithdrawMinimumDays}
                        className="bg-mainHover p-2 h-12 w-12 flex items-center justify-center"
                      >
                        <BiPlus />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Roles able to Stake*/}
              <div className="rounded-lg">
                <div className="bg-third  rounded-lg border border-second shadow">
                  <div className="font-semibold p-2">Select a Role</div>
                  <div className="w-full rounded bg-second">
                    <RoleableStaked
                      onRolesUpdate={handleRoleAbleToStakeUpdate}
                      prevSelectedRoles={roleAbleToStake}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Espaço para mais opções aqui */}
            <div></div>
          </div>
          {/* direita */}
          <div className="flex flex-col lg:w-2/5 w-full h-full rounded-lg gap-5 bg-third py-1.5">
            {/* includeTraitSpecificRewards */}
            <div className="w-full p-2 hidden ">
              <div className="w-full rounded-lg">
                aqui vai ter o switch da TraitBased
              </div>
            </div>
            {/* Collections Rewards */}
            <div className="w-full p-2 flex  ">
              <div className="w-full rounded-lg">
                {dataStaking.length > 0 && (
                  <TraitRewards
                    onCollectionAddress={handleCollectionAddress}
                    rewards={dataStaking.flatMap(
                      (staking) => staking.traitRewardList
                    )}
                    onCollectionsUpdate={handleCollectionsUpdate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------------- */}
      {/* Footer */}
      <div className="flex flex-col w-full h-16 ">
        <div className="w-full flex justify-end ">
          <div className="w-1/3 h-4 bg-third rounded-tl shadow-sm shadow-shadow"></div>
        </div>
        <div className="bg-third h-full"></div>
      </div>
      {/* Button save/close */}
      <div className="flex absolute bottom-3 right-3">
        <Button
          variant="text"
          color="red"
          onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button
          variant="gradient"
          color="deep-purple"
          onClick={updateStakingSetup}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default EditStakingSetup