import { Button, Option, Select, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md'
import { toast } from 'react-toastify'
import TableQuantity from './TableQuantity'
import TableAttribute from './TableTrait'
import { getTraits } from '../../../utils/traitsUtils'

const RoleSetup = ({ syncData, onRolesQuantity, onRolesAttribute, onSales, onListings }) => {
  const [value, setValue] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRoleQty, setSelectedRoleQty] = useState(null)
  const [rolePerQty, setRolePerQty] = useState([])
  const [roleAttribute, setRoleAttribute] = useState([])
  const [selectedRoleAttribute, setSelectedRoleAttribute] = useState(null)
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  const [creatorAddress, setCreatorAddress] = useState('')
  const [traits, setTraits] = useState([])
  const [isSales, setIsSales] = useState(false)
  const [isListings, setIsListings] = useState(false)
  const [collectionAddress, setCollectionAddress] = useState('')

  useEffect(() => {
    if (syncData) {
      setCreatorAddress(syncData.CreatorAddress)
      setCollectionAddress(syncData.CollectionAddress)
    }
  }, [syncData])

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    fetchRoles()
  }, [])

  const increment = () => setValue(value + 1)
  const decrement = () => {
    if (value <= 1) {
      setValue(1)
    } else {
      setValue(value - 1)
    }
  }

  const handleAddRoleQty = () => {
    const newRoleQty = {
      roleId: selectedRoleQty.id,
      roleName: selectedRoleQty.name,
      numberOfNfts: value,
    }
    const exists = rolePerQty.some((role) => role.roleId === newRoleQty.roleId)
    if (!exists) {
      setRolePerQty([...rolePerQty, newRoleQty])
    } else {
      toast.error('Role already exists')
    }
  }

  const handleRemoveRoleQty = (index) => {
    const updatedRoleQty = rolePerQty.filter((_, i) => i !== index)
    setRolePerQty(updatedRoleQty)
  }

  const loadTraits = async () => {
    const data = await getTraits(collectionAddress, creatorAddress)
    setTraits(data)
  }
  useEffect(() => {
    loadTraits()
  }, [collectionAddress, creatorAddress])


  const handleAddRoleAttribute = () => {
    if (selectedRoleAttribute) {
      const newRoleAttribute = {
        roleId: selectedRoleAttribute.id,
        roleName: selectedRoleAttribute.name,
        attributeName: selectedTraitType,
        attributeValue: selectedTraitValue,
      }
      const exists = roleAttribute.some(
        (item) =>
          item.roleId === newRoleAttribute.roleId &&
          item.attributeName === newRoleAttribute.attributeName &&
          item.attributeValue === newRoleAttribute.attributeValue
      )
      if (!exists) {
        const updatedRoleAttribute = [...roleAttribute, newRoleAttribute]
        setRoleAttribute(updatedRoleAttribute)
      } else {
        toast.error('Attribute already exists')
      }
    }
  }

  const handleRemoveRoleAttribute = (index) => {
    const updatedRoleAttribute = roleAttribute.filter((_, i) => i !== index)
    setRoleAttribute(updatedRoleAttribute)
  }

  useEffect(() => {
    onRolesQuantity(rolePerQty)
    onRolesAttribute(roleAttribute)
    onSales(isSales)
    onListings(isListings)
  }, [
    rolePerQty,
    roleAttribute,
    onRolesQuantity,
    onRolesAttribute,
    onSales,
    onListings,
  ])

  return (
    <>
      <section className="container mx-auto text-white flex flex-col py-5 gap-y-5 h-[65vh] overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
        <span className="text-lg font-bold">
          Select the roles and their definitions
        </span>
        <div className="">
          {/* ------------------   Inputs de Roles    -----------------  */}
          <div className="w-full flex flex-col gap-y-5 ">
            {/* Quantity input role */}
            <div className="w-full flex flex-col gap-y-5 ">
              <div className="w-full  flex gap-x-2">
                {/* Quantity input  */}
                <div className="flex flex-col">
                  <div className="w-full"></div>
                  <div className="w-full flex flex-col">
                    <span className="text-sm">Quantity:</span>
                    <div className=" w-full flex flex-col items-end justify-end">
                      <div className="h-full flex items-center justify-end bg-third p-px rounded-lg">
                        <div
                          className={`flex items-end gap-x-0.5 transition duration-300 bg-basic rounded-lg  p-1 selection:bg-second  ${value ? 'bg-second' : ''}`}
                        >
                          <input
                            type="number"
                            min={1}
                            value={value}
                            onChange={(e) => {
                              setValue(Number.parseInt(e.target.value))
                            }}
                            className="w-full bg-transparent focus-within:outline-none px-4 py-1 m-1 "
                          />
                          <div className="h-full flex flex-col gap-y-0.5 items-center justify-center">
                            <Button
                              onClick={increment}
                              className="bg-main hover:bg-mainHover p-1 h-1/2 rounded-b"
                            >
                              <MdOutlineAdd />
                            </Button>
                            <Button
                              onClick={decrement}
                              className="bg-main hover:bg-mainHover p-1 h-1/2 rounded-t"
                            >
                              <MdOutlineRemove />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Select role Quantity*/}
                <div className="w-full flex flex-col justify-end">
                  <span className="text-sm">Select a Role</span>
                  <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                    <Select
                      variant="standard"
                      color="deep-purple"
                      className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                      size="lg"
                      containerProps={{
                        className: 'bg-third ',
                      }}
                      labelProps={{
                        className:
                          'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                      }}
                      menuProps={{
                        className:
                          'text-white bg-third border-none font-semibold',
                      }}
                      onChange={(value) => {
                        const selected = roles.find((role) => role.id === value)
                        setSelectedRoleQty({
                          id: selected.id,
                          name: selected.name,
                        })
                      }}
                    >
                      {roles.map((role) => (
                        <Option
                          className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                          key={role.id}
                          value={role.id}
                        >
                          {role.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {/* Button */}
                <div className="flex items-center justify-center mt-5 ">
                  <Button
                    onClick={handleAddRoleQty}
                    className="bg-main hover:bg-mainHover w-12 h-12 flex items-center justify-center"
                  >
                    ADD
                  </Button>
                </div>
              </div>
              {/* Table quantity */}
              <div className="w-full max-h-[30%] overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
                <TableQuantity
                  quantitys={rolePerQty}
                  onClickDel={handleRemoveRoleQty}
                  del={true}
                />
              </div>
            </div>
            <hr className="h-px mb-5 bg-gray border-0" />
            {/* Role attribute */}
            <div className="w-full flex flex-col gap-y-10">
              <div className="flex flex-col w-full gap-y-1">
                {traits && traits.length > 0 ? (
                  <>
                    <div className="flex gap-x-5">
                      {/* Trait type */}
                      <div className="w-full flex flex-col justify-end">
                        <span className="text-sm">Select Trait Type</span>
                        <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                          <Select
                            variant="standard"
                            color="deep-purple"
                            className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                            size="lg"
                            containerProps={{
                              className: 'bg-third ',
                            }}
                            labelProps={{
                              className:
                                'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                            }}
                            menuProps={{
                              className:
                                'text-white bg-third border-none font-semibold',
                            }}
                            onChange={(value) => {
                              const selected = traits.find(
                                (trait) => trait.TraitType === value
                              )
                              setSelectedTraitType(selected.TraitType)
                            }}
                          >
                            {traits.map((trait) => (
                              <Option
                                className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                                key={trait.TraitType}
                                value={trait.TraitType}
                              >
                                {trait.TraitType}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      {/* Trait value */}
                      <div className="w-full flex flex-col justify-end">
                        <span className="text-sm">Select Trait Type</span>
                        <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                          <Select
                            variant="standard"
                            color="deep-purple"
                            className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                            size="lg"
                            containerProps={{
                              className: 'bg-third ',
                            }}
                            labelProps={{
                              className:
                                'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                            }}
                            menuProps={{
                              className:
                                'text-white bg-third border-none font-semibold',
                            }}
                            onChange={(value) => {
                              setSelectedTraitValue(value)
                            }}
                          >
                            {traits
                              .filter(
                                (trait) => trait.TraitType === selectedTraitType
                              )
                              .flatMap((trait) => trait.TraitValue)
                              .map((value, index) => (
                                <Option
                                  className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                                  key={index}
                                  value={value}
                                >
                                  {value}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full gap-x-5">
                      {/* Role */}
                      <div className="w-full flex flex-col justify-end">
                        <span className="text-sm">Select Trait Type</span>
                        <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                          <Select
                            variant="standard"
                            color="deep-purple"
                            className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                            size="lg"
                            containerProps={{
                              className: 'bg-third ',
                            }}
                            labelProps={{
                              className:
                                'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                            }}
                            menuProps={{
                              className:
                                'text-white bg-third border-none font-semibold',
                            }}
                            onChange={(value) => {
                              const selected = roles.find(
                                (role) => role.id === value
                              )
                              setSelectedRoleAttribute({
                                id: selected.id,
                                name: selected.name,
                              })
                            }}
                          >
                            {roles.map((role) => (
                              <Option key={role.id} value={role.id}>
                                {role.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-center mt-5">
                        <Button
                          onClick={handleAddRoleAttribute}
                          className="bg-main hover:bg-mainHover w-12 h-12 flex items-center justify-center"
                        >
                          ADD
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <h1 className="text-white text-xl">No traits found</h1>
                  </div>
                )}
              </div>
              <div className=" max-h-[30%] w-full overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
                <TableAttribute
                  traits={roleAttribute}
                  onClickDel={handleRemoveRoleAttribute}
                  del={true}
                />
              </div>
            </div>
          </div>
          <hr className="h-px mb-5 bg-gray border-0" />

          {/* ------------------   Switchs    -----------------  */}
          <div className="container mx-auto flex flex-col gap-y-2">
            <Switch
              className={`${isSales ? 'bg-mainHover' : ''}`}
              label="Sales"
              checked={isSales}
              onChange={() => {
                const newSalesState = !isSales;
                setIsSales(newSalesState);
                onSales(newSalesState);
              }}
            />
            <Switch
              className={`${isListings ? 'bg-mainHover' : ''}`}
              label="Listings"
              checked={isListings}
              onChange={() => {
                const newListingsState = !isListings;
                setIsListings(newListingsState);
                onListings(newListingsState);
              }}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default RoleSetup