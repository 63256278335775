import { Button, Card, Input } from '@material-tailwind/react'
import React, { useState, useEffect, useRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { BiMinus, BiPlus, BiSearch } from 'react-icons/bi'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getTraits } from '../../../utils/traitsUtils'
import { getCollection } from '../../../utils/collectionUtils'

const TraitRewards = ({ rewards, onCollectionsUpdate, onCollectionAddress }) => {
  const [collectionAddress, setCollectionAddress] = useState(null)
  const [creatorAddress, setCreatorAddress] = useState('')
  const [traits, setTraits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const animatedComponents = makeAnimated()
  const [searchQuery, setSearchQuery] = useState('')
  const [collection, setCollection] = useState([])
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  

  const traitTypeRef = useRef(null)
  const traitValueRef = useRef(null)

  //load collections
  const [collections, setCollections] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)
  const hasLoadedRef = useRef(false)

  useEffect(() => {
    const loadCollections = async () => {
      const data = await getCollection()
      setCollections(data)
      console.log(data)
    }
    if (!hasLoadedRef.current) {
      loadCollections()
      hasLoadedRef.current = true
    }
  }, [])

  const collectionOptions = collections?.flatMap((collection) =>
    collection.CollectionNotifications
      ?.filter((notification) => notification.IsEnabled)
      .map((notification) => ({
        value: notification.CollectionAddress,
        label: notification.DisplayName,
      })) || [] 
  );

  const loadTraits = async () => {
    if (!collectionAddress) return
    setIsLoading(true)
    const data = await getTraits(collectionAddress, creatorAddress)
    setTraits(data)
    setIsLoading(false)
  }

  useEffect(() => {
    loadTraits()
  }, [collectionAddress])

  const handleCollectionChange = (selectedOption) => {
    setSelectedCollection(selectedOption)
    setCollectionAddress(selectedOption.value)
    setCreatorAddress('')
    onCollectionAddress(selectedOption.value)
  }

  console.log('traits', traits)
  const traitOptions = traits?.map((trait) => ({
    value: trait.TraitType,
    label: trait.TraitType,
  }))

  const traitValueOptions = selectedTraitType
    ? traits
      ?.find((trait) => trait.TraitType === selectedTraitType.value)
      ?.TraitValue?.map((value) => ({
        value,
        label: value,
      })) || [] 
    : [];

  const [valueTotalReward, setValueTotalReward] = useState(1)
  const incrementTotalReward = () => {
    setValueTotalReward((prevValue) => prevValue + 1)
  }
  const decrementTotalReward = () => {
    setValueTotalReward((prevValue) => (prevValue <= 1 ? 1 : prevValue - 1))
  }

  const handleAddTrait = () => {
    if (!selectedTraitType || !selectedTraitValue) return

    const newTrait = {
      type: selectedTraitType.value,
      value: selectedTraitValue.value,
      reward: valueTotalReward,
    }

    // Verificar se o item já existe na coleção
    const exists = collection.some(
      (trait) => trait.type === newTrait.type && trait.value === newTrait.value
    )

    if (exists) {
      toast.error('Item já existe na coleção!')
    } else {
      const updatedCollection = [newTrait, ...collection]
      setCollection(updatedCollection)
      onCollectionsUpdate(updatedCollection)
    }

    // Resetar os valores
    setSelectedTraitType(null)
    setSelectedTraitValue(null)
    setValueTotalReward(1)
    traitTypeRef.current?.clearValue()
    traitValueRef.current?.clearValue()
  }

  const handleDeleteTrait = (traitToDelete) => {
    const updatedCollection = collection.filter((trait) => {
      return (
        trait.type !== traitToDelete.type || trait.value !== traitToDelete.value
      )
    })
    setCollection(updatedCollection)
    onCollectionsUpdate(updatedCollection)
  }

  console.log('collectionAddress', collectionAddress)
  return (
    <>
      <div className="flex flex-col gap-y-2">
        {/* Select Collection */}
        <div>
          <Select
            options={collectionOptions}
            components={animatedComponents}
            onChange={handleCollectionChange}
            placeholder="Select Collection"
            className="basic-single-select"
            classNamePrefix="select"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#2C2F48',
                color: '#fff',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                height: 'auto',
                maxHeight: '100%',
              }),
              container: (provided, state) => ({
                ...provided,
                margin: 0,
                padding: 0,
                border: 0,
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#fff',
              }),
              control: (provided, state) => ({
                ...provided,
                height: 'auto',
                maxHeight: '100%',
                maxWidth: '100%',
                backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                color: '#fff',
                borderRadius: 8,
                borderColor:
                  state.isFocused || state.isHovered
                    ? '#9945ff'
                    : '#2C2F48',
                boxShadow:
                  state.isFocused || state.isHovered
                    ? '0 0 0 0.5px #9945ff'
                    : 'none',
                paddingLeft: '1rem',
                '&:hover': {
                  borderColor: '#9945ff',
                  boxShadow: '0 0 0 0.5px #9945ff',
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? '#9945ff'
                  : state.isFocused
                    ? '#9945ff'
                    : '#393D5E',
                color: '#fff',
                cursor: 'pointer',
                padding: 0,
                paddingLeft: '1rem',
                margin: 0,
              }),
            }}
          />
        </div>
        {/* ADD Traits Rewards */}
        {selectedCollection && (
          <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-x-1">
            {/* TRAITS */}
            <div className="w-full flex gap-x-1">
              {/* NFT TYPE */}
              <div className="w-full">
                <Select
                  isClearable
                  isLoading={isLoading}
                  isDisabled={!selectedCollection}
                  options={traitOptions}
                  components={animatedComponents}
                  onChange={(selectedOption) => {
                    setSelectedTraitType(selectedOption)
                    setSelectedTraitValue(null)
                    traitValueRef.current.clearValue()
                  }}
                  placeholder="Select Type"
                  className="basic-single-select"
                  classNamePrefix="select"
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#2C2F48',
                      color: '#fff',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                      height: 'auto',
                      maxHeight: '100%',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      margin: 0,
                      padding: 0,
                      border: 0,
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#fff',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                      color: '#fff',
                      borderRadius: 8,
                      borderColor:
                        state.isFocused || state.isHovered
                          ? '#9945ff'
                          : '#2C2F48',
                      boxShadow:
                        state.isFocused || state.isHovered
                          ? '0 0 0 0.5px #9945ff'
                          : 'none',
                      paddingLeft: '1rem',
                      '&:hover': {
                        borderColor: '#9945ff',
                        boxShadow: '0 0 0 0.5px #9945ff',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? '#9945ff'
                        : state.isFocused
                          ? '#9945ff'
                          : '#393D5E',
                      color: '#fff',
                      cursor: 'pointer',
                      padding: 0,
                      paddingLeft: '1rem',
                      margin: 0,
                    }),
                  }}
                  ref={traitTypeRef}
                />
              </div>
              {/* NFT VALUE */}
              <div className="w-full">
                <Select
                  isClearable
                  options={traitValueOptions}
                  components={animatedComponents}
                  onChange={(selectedOption) => {
                    setSelectedTraitValue(selectedOption)
                  }}
                  isDisabled={!selectedTraitType}
                  placeholder="Select Value"
                  className="basic-single-select"
                  classNamePrefix="select"
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#2C2F48',
                      color: '#fff',
                      overflowY: 'hidden',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                      height: 'auto',
                      maxHeight: '100%',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      margin: 0,
                      padding: 0,
                      border: 0,
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#fff',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                      color: '#fff',
                      borderRadius: 8,
                      borderColor:
                        state.isFocused || state.isHovered
                          ? '#9945ff'
                          : '#2C2F48',
                      boxShadow:
                        state.isFocused || state.isHovered
                          ? '0 0 0 0.5px #9945ff'
                          : 'none',
                      paddingLeft: '1rem',
                      '&:hover': {
                        borderColor: '#9945ff',
                        boxShadow: '0 0 0 0.5px #9945ff',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? '#9945ff'
                        : state.isFocused
                          ? '#9945ff'
                          : '#393D5E',
                      color: '#fff',
                      cursor: 'pointer',
                      padding: 0,
                      paddingLeft: '1rem',
                      margin: 0,
                      overflow: 'hidden',
                    }),
                  }}
                  ref={traitValueRef}
                />
              </div>
            </div>
            <div className="w-full lg:w-fit flex justify-end gap-x-1">
              {/* QUANTITY REWARD */}
              <div className="flex items-center h-full transition duration-300 rounded-lg shadow  bg-second border-second border ">
                <input
                  type="number"
                  min={1}
                  value={valueTotalReward}
                  onChange={(e) => setValueTotalReward(parseInt(e.target.value))}
                  className=" h-full text-white p-2 first-of-type:pl-4 border-none bg-transparent w-16 outline-none"
                />
                <div className="flex flex-col h-full">
                  <button
                    onClick={incrementTotalReward}
                    className="bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
                  >
                    <BiPlus />
                  </button>
                  <hr className="border-second" />
                  <button
                    onClick={decrementTotalReward}
                    className="bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
                  >
                    <BiMinus />
                  </button>
                </div>
              </div>
              {/* ADD BUTTON */}
              <div className="flex justify-end">
                <Button
                  className="bg-mainHover p-2 flex items-center justify-center"
                  onClick={handleAddTrait}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        )}
        {/* Table Traits Rewards */}
        <Card className=" bg-basic h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow ">
          <Input
            placeholder="Filtrar por tipo ou valor"
            variant="static"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            className="w-full bg-second border-none px-5"
            icon={<BiSearch className="text-xl" />}
          />
          <table className="w-full text-left">
            <thead className="text-xs bg-second uppercase">
              <tr>
                <th
                  scope="col"
                  className="text-center border-r border-third py-2"
                >
                  Trait
                </th>
                <th
                  scope="col"
                  className="text-center py-2 border-r border-third"
                >
                  Value
                </th>
                <th scope="col" className="py-2 text-center">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              {collection?.map((trait, index) => (
                <tr
                  key={index}
                  className="bg-basic hover:bg-third border-second border-t"
                >
                  <td className="p-2 border-second text-center flex gap-x-3">
                    <button
                      onClick={() => handleDeleteTrait(trait)}
                      className="flex justify-center bg-transparent p-0.5 items-center"
                    >
                      <AiOutlineClose className="text-xl font-bold text-red-500" />
                    </button>
                    {trait.type}
                  </td>
                  <td className="p-2 border-second pl-5 text-left">
                    {trait.value}
                  </td>
                  <td className="p-2 border-second text-center ">
                    {trait.reward}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  )
}


export default TraitRewards