import { Input } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import SectionTokenCreation from '../TokenCreation/SectionTokenCreation'
import Collection from '../components/SelectCollection'
import Traits from '../components/SelectTrait'
import Channel from '../components/multiselectChannel'
import Role from '../components/multiselectRole'
import Button from '../components/ui/button'
import Checkbox from '../components/ui/checkbox'
import Modal from '../components/ui/modal'
import DropdownAmountDay from './_components/DropdownAmountDay'
import { InputHolderDay } from './_components/InputHolderDay'
import InputRewardTrait from './_components/InputRewardTrait'
import InputWithdraw from './_components/InputWithdraw'
import SwitchSpecificReward from './_components/SwitchSpecificReward'
import TokenOption from './_components/TokenOption'

const CreateStakingSetup = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(!open)
  const [selectedRole, setSelectedRole] = useState('')
  const inheritSelectRole = (selectedRole) => {
    setSelectedRole(selectedRole)
  }
  const [selectedChannel, setSelectedChannel] = useState('')
  const inheritSelectChannel = (selectedChannel) => {
    setSelectedChannel(selectedChannel)
  }
  const [selectedCollection, setSelectedCollection] = useState('')
  const inheritSelectCollection = (selectedCollection) => {
    setSelectedCollection(selectedCollection)
  }
  console.log('selectedCollection', selectedCollection)

  const [selectedTraitType, setSelectedTraitType] = useState('')
  const inheritSelectTraitType = (selectedTraitType) => {
    setSelectedTraitType(selectedTraitType)
  }
  const [selectedTraitValue, setSelectedTraitValue] = useState('')
  const inheritSelectTraitValue = (selectedTraitValue) => {
    setSelectedTraitValue(selectedTraitValue)
  }
  const [selectedAmountDay, setSelectedAmountDay] = useState('')
  const inheritSelectAmountDay = (selectedAmountDay) => {
    setSelectedAmountDay(selectedAmountDay)
  }
  const creatorAddress = selectedCollection.value
  const [isCheckedServerPoints, setIsCheckedServerPoints] = useState(false)
  const [isCheckedTokenOption, setIsCheckedTokenOption] = useState(false)
  const [tokenName, setTokenName] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [quantityHolderDay, setQuantityHolderDay] = useState('')
  const [quantityWithdraw, setQuantityWithdraw] = useState('')
  const [isCheckedSpecificReward, setIsCheckedSpecificReward] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tokenInfo, setTokenInfo] = useState(null)
  const [returnTokenInfo, setReturnTokenInfo] = useState([])
  const handleTokenInfoChange = (info) => {
    setTokenInfo(info)
  }

  useEffect(() => {
    if (returnTokenInfo.length > 0) {
      setTokenAddress(returnTokenInfo[0].tokenAddress)
      setTokenName(returnTokenInfo[0].tokenName)
    }
  }, [returnTokenInfo])

  useEffect(() => {
    if (isCheckedTokenOption) {
      setIsModalOpen(true)
    }
  }, [isCheckedTokenOption])

  const handleClickTokenCancel = (event) => {
    setIsModalOpen(false)
    setIsCheckedTokenOption(event.target.checked)
  }

  const handleClickTokenSave = () => {
    if (!tokenInfo || Object.values(tokenInfo).some((value) => !value)) {
      toast.error('Please fill in all fields before saving the token.')
      return
    }
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/token`
    toast.promise(
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tokenInfo),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('API response error')
          }
          return response.json()
        })
        .then((data) => {
          toast.success('Token created successfully!')
          setReturnTokenInfo([
            {
              tokenAddress: data.tokenAddress,
              tokenName: tokenInfo.name,
            },
          ])
          setIsModalOpen(false)
          setIsCheckedTokenOption(false)
        })
        .catch((error) => {
          console.error('Error sending to the API:', error)
          toast.error(`Error creating token: ${error.message}`)
        }),
      {
        pending: 'Creating token...',
        success: 'Token created successfully!',
        error: 'Error creating token!',
      }
    )
  }

  const handleCheckboxChange = (event) => {
    setIsCheckedServerPoints(event.target.checked)
  }

  const handleClickCancel = () => {
    alert('clicou no cancelar')
  }

  const handleClickSave = async () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/staking/setup`
    if (isCheckedServerPoints == false) {
      if (
        selectedRole &&
        tokenName &&
        tokenAddress &&
        quantityHolderDay &&
        quantityWithdraw &&
        selectedAmountDay &&
        selectedCollection &&
        selectedChannel
      ) {
        try {
          const collectedInfo = {
            guildId: localStorage.getItem('selectedServer'),
            eligibleRole: selectedRole.value,
            tokenName: tokenName,
            tokenAddress: tokenAddress,
            dailyRewardAmount: quantityHolderDay,
            minimumWithdrawalBalance: quantityWithdraw,
            minimumWithdrawalDays: selectedAmountDay,
            stakingCollectionAddress: selectedCollection.value,
            stakingChannelId: selectedChannel.value,
            isNewToken: false, //teste
            includeRewardDetails: true, //teste
            includeTraitSpecificRewards: false, //teste
            usesPointSystem: false, //teste
          }
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(collectedInfo),
          })
        } catch (error) {
          console.error('Erro ao enviar dados para a API:', error)
        }
      }
    } else {
      toast.error('Ops, review empty fields!.')
    }
  }

  return (
    <section className="w-full h-full text-white py-5 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic container mx-auto">
      <div className="absolute top-0 left-0 w-full p-5 flex flex-col bg-dark z-50">
        <h1 className="text-4xl font-bold">Staking Setup</h1>
      </div>
      <div className="flex flex-col py-20">
        {/* Role select */}
        <div className="flex flex-col gap-y-5 py-5">
          <h2 className="text-2xl font-medium">
            Select the role that can stake
          </h2>
          <div className="flex flex-row gap-x-5 items-end">
            <div className="w-1/2">
              <Role onSelectRole={inheritSelectRole} isMulti={false} />
            </div>
            <Checkbox
              checked={isCheckedServerPoints}
              onChange={handleCheckboxChange}
              label="Server Points"
            />
          </div>
          <hr className="h-px mb-5 bg-gray border-0" />
        </div>
        {/* Token Section */}
        {!isCheckedServerPoints && (
          <div className="w-full gap-y-5 transition-all duration-700 ">
            <div className="w-1/2">
              <TokenOption
                isChecked={isCheckedTokenOption}
                setIsChecked={setIsCheckedTokenOption}
              />
            </div>
            <div className="py-5">
              {isCheckedTokenOption && (
                <Modal isOpen={isModalOpen} onClose={handleClickTokenCancel}>
                  <SectionTokenCreation
                    onTokenInfoChange={handleTokenInfoChange}
                  />
                  <div className="flex justify-end gap-x-5 pt-10 mx-5">
                    <Button
                      onClick={handleClickTokenCancel}
                      type="reset"
                      className="w-40 bg-basic border"
                    >
                      Cancelar
                    </Button>
                    <Button onClick={handleClickTokenSave} type="button">
                      Save
                    </Button>
                  </div>
                </Modal>
              )}

              {/* TokenInformation */}
              <div className="w-full flex flex-row gap-x-5">
                <div className="w-1/2 bg-third rounded-lg">
                  <Input
                    label="Enter the name of token*"
                    placeholder="Example: Bonk, King, Dust, etc."
                    variant="outlined"
                    color="deep-purple"
                    size="lg"
                    className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
                    labelProps={{
                      className:
                        'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
                    }}
                    value={tokenName}
                    onChange={(e) => {
                      setTokenName(e.target.value)
                      returnTokenInfo.tokenName
                    }}
                  />
                </div>
                <div className="w-1/2 bg-third rounded-lg">
                  <Input
                    label="Enter the token address here*"
                    placeholder="Example: DezXAZ8z7PnrnRjz3wXBoRgixCa6xjnB7"
                    color="deep-purple"
                    variant="outlined"
                    size="lg"
                    className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
                    labelProps={{
                      className:
                        'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
                    }}
                    value={tokenAddress}
                    onChange={(e) => {
                      setTokenAddress(e.target.value)
                      returnTokenInfo.tokenAddress
                    }}
                  />
                </div>
              </div>
            </div>
            <hr className="h-px mb-5 bg-gray border-0" />
          </div>
        )}
        {/* Rewards Details section */}
        <div>
          <h2 className="text-2xl font-medium">Rewards Details</h2>
          <div className="flex flex-row gap-x-5 py-5">
            <InputHolderDay
              quantity={quantityHolderDay}
              setQuantity={setQuantityHolderDay}
            />
            <InputWithdraw
              quantity={quantityWithdraw}
              setQuantity={setQuantityWithdraw}
            />
            <DropdownAmountDay onAmountDayChange={inheritSelectAmountDay} />
          </div>
          <hr className="h-px mb-5 bg-gray border-0" />
        </div>
        {/* Select the collection that can stake */}
        <div className="flex flex-col">
          <h2 className="text-2xl font-medium">
            Select the collection that can stake
          </h2>
          <div className="flex flex-row gap-x-5 py-5">
            <Collection
              onSelectCollection={inheritSelectCollection}
              isMulti={false}
            />
            <Channel onSelectChannel={inheritSelectChannel} isMulti={false} />
          </div>
          {!isCheckedServerPoints && (
            <div>
              <div className="py-5">
                <h2 className="pb-5">
                  You can create custom trait specific reward basedon your NFT
                  metadata to give its holder extra rewards, example: NFTs with
                  trait Head, trait value Golden Necklace, get 10 rewards per
                  day.
                </h2>
                <SwitchSpecificReward
                  isChecked={isCheckedSpecificReward}
                  setIsChecked={setIsCheckedSpecificReward}
                />
              </div>

              {isCheckedSpecificReward && (
                <div>
                  <h2 className="text-2xl font-medium">
                    Information about your trait
                  </h2>
                  <div className="w-full flex flex-row gap-x-5 py-5">
                    <Traits
                      creatorAddress={creatorAddress}
                      onSelectTraitType={inheritSelectTraitType}
                      onSelectTraitValue={inheritSelectTraitValue}
                    />
                    <InputRewardTrait />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* Button section  */}

      <div className="absolute bottom-0 left-0 flex flex-row container mx-auto w-full justify-between py-5 bg-dark shadow-sm shadow-black">
        <Button
          onClick={handleClickCancel}
          type="reset"
          className="w-40 bg-basic border"
        >
          Cancelar
        </Button>
        <Button onClick={handleClickSave} type="button">
          Save
        </Button>
      </div>
    </section>
  )
}

export default CreateStakingSetup
