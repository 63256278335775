import React, { useState, useEffect } from 'react'
import { Alert, Button, Option, Select, Spinner } from '@material-tailwind/react'
import { getTraits } from '../../../utils/traitsUtils'

const RoleAttibute = ({
  dataRoleAttribute,
  addButton,
  confirmAttribute,
  creatorAddress,
  collectionAddress,
}) => {
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [roleAttribute, setRoleAttribute] = useState([])
  let [traits, setTraits] = useState(null)
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  const [newTraits, setNewTraits] = useState([])

  // Load roles
  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('guildId')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [roles.length])

  // Load traits 
  const loadTraits = async () => {
    const data = await getTraits(collectionAddress, creatorAddress)
    setNewTraits(data)
    console.log('data', data)
  }
  useEffect(() => {
    loadTraits()
  }, [collectionAddress, creatorAddress])

  const handleAddRoleAttribute = () => {
    if (selectedRole && selectedTraitType && selectedTraitValue) {
      const newRoleAttribute = {
        roleId: selectedRole.id,
        roleName: selectedRole.name,
        attributeName: selectedTraitType,
        attributeValue: selectedTraitValue,
      }
      const exists = confirmAttribute.some(
        (item) =>
          item.roleId === newRoleAttribute.roleId &&
          item.attributeName === newRoleAttribute.attributeName &&
          item.attributeValue === newRoleAttribute.attributeValue
      )
      if (!exists) {
        const updatedRoleAttribute = [...roleAttribute, newRoleAttribute]
        setRoleAttribute(updatedRoleAttribute)
        dataRoleAttribute(updatedRoleAttribute)
        addButton(newRoleAttribute)
      }
    }
  }

  return (
    <div className="flex flex-col h-full w-full gap-y-5">
      {newTraits && newTraits.length > 0 ? (
        <>
          <div className="flex gap-x-5">
            {/* Trait type */}
            <div className="w-full flex flex-col justify-end">
              <span className="text-sm">Select Trait Type</span>
              <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                <Select
                  variant="standard"
                  color="deep-purple"
                  className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                  size="lg"
                  containerProps={{
                    className: 'bg-third ',
                  }}
                  labelProps={{
                    className:
                      'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                  }}
                  menuProps={{
                    className:
                      'text-white bg-third border-none font-semibold',
                  }}
                  onChange={(value) => {
                    const selected = newTraits.find(
                      (trait) => trait.TraitType === value
                    )
                    setSelectedTraitType(selected.TraitType)
                  }}
                >
                  {newTraits.map((trait) => (
                    <Option
                      className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                      key={trait.TraitType}
                      value={trait.TraitType}
                    >
                      {trait.TraitType}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* Trait value */}
            <div className="w-full flex flex-col justify-end">
              <span className="text-sm">Select Trait Type</span>
              <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                <Select
                  variant="standard"
                  color="deep-purple"
                  className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                  size="lg"
                  containerProps={{
                    className: 'bg-third ',
                  }}
                  labelProps={{
                    className:
                      'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                  }}
                  menuProps={{
                    className:
                      'text-white bg-third border-none font-semibold',
                  }}
                  onChange={(value) => {
                    setSelectedTraitValue(value)
                  }}
                >
                  {newTraits
                    .filter(
                      (trait) => trait.TraitType === selectedTraitType
                    )
                    .flatMap((trait) => trait.TraitValue)
                    .map((value, index) => (
                      <Option
                        className=" bg-third hover:text-main hover:bg-second peer-focus:bg-second  hover:pl-7 transition-all duration-300 peer-selected:bg-second peer-selected:text-main  "
                        key={index}
                        value={value}
                      >
                        {value}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex w-full gap-x-5">
            {/* Role */}
            <div className="w-full flex flex-col justify-end">
              <span className="text-sm">Select Trait Type</span>
              <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                <Select
                  variant="standard"
                  color="deep-purple"
                  className="focus:outline-none border-none bg-second focus:shadow-inner focus:pl-5 w-full text-white p-2 tracking-widest font-bold text-lg first-of-type:pl-4 "
                  size="lg"
                  containerProps={{
                    className: 'bg-third ',
                  }}
                  labelProps={{
                    className:
                      'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg placeholder-shown:text-white  text-sm tracking-widest absolute -top-5  h-[62px] peer-focus:text-white label-shown:bg-third ',
                  }}
                  menuProps={{
                    className:
                      'text-white bg-third border-none font-semibold',
                  }}
                  onChange={(value) => {
                    const selected = roles.find(
                      (role) => role.id === value
                    )
                    setSelectedRole({
                      id: selected.id,
                      name: selected.name,
                    })
                  }}
                >
                  {roles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {/* Button */}
            <div className="flex items-center justify-center mt-5">
              <Button
                onClick={handleAddRoleAttribute}
                className="bg-main hover:bg-mainHover w-12 h-12 flex items-center justify-center"
              >
                ADD
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h1 className="text-white text-xl">No traits found</h1>
        </div>
      )}
    </div>
  )
}

export default RoleAttibute
