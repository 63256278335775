import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Input,
  Option,
  Select,
  Switch,
  Accordion,
  AccordionHeader,
  AccordionBody,
  IconButton,
  Tooltip,
} from '@material-tailwind/react'
import { useCopyToClipboard } from "usehooks-ts";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import { BiX } from 'react-icons/bi'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify'
import ButtonEdit from './_components/ButtonEdit'
import RoleAttibute from './_components/RoleAttibute'
import RoleQuantity from './_components/RoleQuantity'
import TableQuantity from './_components/TableQuantity'
import TableTrait from './_components/TableTrait'

const EditHolderVerification = ({ selectedCard }) => {
  const [cardSelected, setCardSelected] = useState(selectedCard)
  console.log(cardSelected)

  const [openDetails, setOpenDetails] = useState(false)
  const handleOpenDetails = () => setOpenDetails(!openDetails)

  const [value, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);

  return (
    <>
      <section className="h-[60vh] relative">
        {/* header */}
        <div>
          <div className="text-3xl font-bold text-white pl-1">{cardSelected.DisplayName}</div>
          <hr />
        </div>
        {/* body */}
        <div className="h-full flex flex-row p-2 gap-5">
          <div className="bg-dark/70 w-1/3 h-full p-2">esquerda
            <div className='h-2/3'>foto da coleção</div>
            <div className='h-1/3 text-white'>
              <Accordion className='text-white' open={openDetails}>
                <AccordionHeader onClick={handleOpenDetails}>
                  <div className='text-xs font-bold text-white -mb-2 pl-1'>DETAILS</div>
                </AccordionHeader>
                <AccordionBody className='px-2'>
                  <div className='flex gap-x-3 w-full items-center justify-between'>
                    <div>Collection Address</div>
                    <div className='w-40 pl-1 flex items-center justify-end relative group'>
                      <IconButton
                        onMouseLeave={() => setCopied(false)}
                        onClick={() => {
                          copy(cardSelected.CollectionAddress);
                          setCopied(true);
                        }}
                      >
                        {copied ? (
                          <CheckIcon className="h-5 w-5 text-white" />
                        ) : (
                          <DocumentDuplicateIcon className="h-5 w-5 text-white" />
                        )}
                      </IconButton>
                        <div className='w-full '>
                          <div className='truncate pl-3'>{cardSelected.CollectionAddress}</div>
                        </div>
                          <div className='absolute -top-4 right-0 py-1 px-2 bg-second/50 shadow-lg hidden group-hover:block '>
                            {cardSelected.CollectionAddress}
                          </div>
                    </div>
                  </div>
                  <div className='flex gap-x-3 w-full items-center'>
                    <div>Creator Address</div>
                    <div className='w-40 pl-1 flex items-center justify-end'>
                      <IconButton
                        onMouseLeave={() => setCopied(false)}
                        onClick={() => {
                          copy(cardSelected.VerifiedAddress);
                          setCopied(true);
                        }}
                      >
                        {copied ? (
                          <CheckIcon className="h-5 w-5 text-white" />
                        ) : (
                          <DocumentDuplicateIcon className="h-5 w-5 text-white" />
                        )}
                      </IconButton>
                      <Tooltip content={cardSelected.VerifiedAddress} placement='top'>
                        <div className='truncate pl-3'>{cardSelected.VerifiedAddress}</div>
                      </Tooltip>
                    </div>
                  </div>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
          <div className="bg-indigo-900/70 w-2/3 h-full">direita</div>
        </div>
      </section>
    </>
  )
}

export default EditHolderVerification
