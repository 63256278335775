import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const RoleableStaked = ({ onRolesUpdate, prevSelectedRoles }) => {
  const animatedComponents = makeAnimated()
  const [isLoading, setIsLoading] = useState(true)
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [firstSelectedRoles, setFirstSelectedRoles] = useState(
    prevSelectedRoles[0]
  )
  const hasFetchedRoles = useRef(false)

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
        )
        const data = await response.json()
        setRoles(data)

        // Pré-selecionar os itens com base em prevSelectedRoles
        if (prevSelectedRoles && prevSelectedRoles.length > 0) {
          const preSelected = data
            .filter((role) => firstSelectedRoles.includes(role.id))
            .map((role) => ({
              value: role.id,
              label: role.name,
            }))
          setSelectedRoles(preSelected)
          onRolesUpdate(preSelected)
        }
      } catch (error) {
        console.error('Error fetching roles:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (!hasFetchedRoles.current) {
      fetchRoles()
      hasFetchedRoles.current = true // Marca como carregado
    }
  }, []) // Dependência vazia para garantir que execute apenas uma vez

  const dropdownRoles = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }))

  return (
    <>
      <Select
        options={dropdownRoles}
        isMulti
        components={animatedComponents}
        value={selectedRoles} // Definir os valores selecionados
        onChange={(selectedOptions) => {
          setSelectedRoles(selectedOptions)
          onRolesUpdate(selectedOptions)
        }}
        placeholder="Select Roles"
        className="basic-multi-select"
        classNamePrefix="select"
        styles={{
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#2C2F48',
            color: '#fff',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: state.hasValue ? '0.5rem 0' : '0.5rem',
            height: 'auto',
            maxHeight: '100%',
          }),
          container: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            border: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
          }),
          control: (provided, state) => ({
            ...provided,
            height: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
            backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
            color: '#fff',
            borderRadius: 8,
            borderColor:
              state.isFocused || state.isHovered ? '#9945ff' : '#2C2F48',
            boxShadow:
              state.isFocused || state.isHovered
                ? '0 0 0 0.5px #9945ff'
                : 'none',
            paddingLeft: '1rem',
            '&:hover': {
              borderColor: '#9945ff',
              boxShadow: '0 0 0 0.5px #9945ff',
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? '#9945ff'
              : state.isFocused
                ? '#9945ff'
                : '#2C2F48',
            color: '#fff',
            cursor: 'pointer',
            padding: 0,
            paddingLeft: '1rem',
            margin: 0,
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#9945ff',
            color: '#fff',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
          }),
        }}
      />
    </>
  )
}

export default RoleableStaked
