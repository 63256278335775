import { Button, Input, Option, Select } from '@material-tailwind/react'
import { Alert } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify'

const RoleQuantity = ({ dataRoleQty, confirmQty, addButton, del }) => {
  const [value, setValue] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [roleQuantity, setRoleQuantity] = useState([])
  const [alert, setAlert] = useState({ show: false, message: '', type: '' })

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrlSelectRole)
      const data = await response.json()
      setRoles(data)
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [])

  const increment = () => setValue(value + 1)
  const decrement = () => {
    if (value <= 1) {
      setValue(1)
    } else {
      setValue(value - 1)
    }
  }

  const handleAddRoleQty = () => {
    if (selectedRole && value > 0) {
      const newRoleQty = {
        numberOfNfts: value,
        roleId: selectedRole.id,
        roleName: selectedRole.name,
      }
      const exists = confirmQty.some(
        (item) => item.roleId === newRoleQty.roleId
      )

      if (!exists) {
        const updatedRoleQuantity = [...roleQuantity, newRoleQty]
        setRoleQuantity(updatedRoleQuantity)
        dataRoleQty(updatedRoleQuantity)
        addButton(newRoleQty)
      } else {
        toast.error('Opss, role already added with a different quantity')
      }
    } else {
      toast.error('Opss, select a role and quantity')
    }
  }

  return (
    <div className="flex gap-x-2 ">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex items-end gap-x-0.5 w-1/3 transition duration-300 ">
        <input
          type="number"
          min={1}
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
          className="w-14 bg-transparent border-white/60 border-b focus:border-mainHover focus-within:outline-none px-2 py-1 text-center "
        />
        <div className="h-full flex flex-col gap-y-0.5 items-center justify-center">
          <Button
            onClick={increment}
            className="bg-mainHover p-1 h-1/2 rounded-b"
          >
            <MdOutlineAdd />
          </Button>
          <Button
            onClick={decrement}
            className="bg-mainHover p-1 h-1/2 rounded-t"
          >
            <MdOutlineRemove />
          </Button>
        </div>
      </div>
      <div className="w-4/5 flex gap-x-5">
        <Select
          variant="static"
          color="deep-purple"
          className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white px-2"
          labelProps={{
            className:
              'peer-focus:text-white peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-white',
          }}
          label="Select Role"
          onChange={(value) => {
            const selected = roles.find((role) => role.id === value)
            setSelectedRole({ id: selected.id, name: selected.name })
          }}
        >
          {roles.map((role) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
        <div className="h-full">
          <Button
            onClick={handleAddRoleQty}
            className="bg-mainHover p-2 h-full w-full "
          >
            ADD
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RoleQuantity
